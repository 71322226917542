import { useEffect } from "react";
import { navigate } from "gatsby";

function NotFoundPage(props) {
  useEffect(() => {
      navigate('/');
  }, []);
  return null;
}

export default NotFoundPage
